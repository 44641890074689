import {
  Center,
  chakra,
  Container,
  Flex,
  HStack,
  Link,
  VStack,
} from "@chakra-ui/react"
import { RightVHalfArrow } from "components/icons/icons"
import { HeadNav } from "components/layouts/HeadNav"
import { Wbr } from "components/system/text/wbr"
import NextImage from "next/image"
import NextLink from "next/link"

const NotFound = () => {
  return (
    <Container maxW="container.xl">
      <Header />
      <Main />
    </Container>
  )
}

export default NotFound

const Header = () => {
  return (
    <>
      <chakra.header
        w="full"
        py={{ base: 4, lg: 6 }}
        px={{ base: 5, lg: 8 }}
        position="relative"
      >
        <HStack
          // zIndex={2}
          mx="auto"
          maxW="container.xl"
          pos="relative"
          align="baseline"
          zIndex={2}
        >
          <chakra.div>
            <NextLink href="/" passHref>
              <Link d="block" minHeight={8}>
                <NextImage
                  alt="monicle"
                  src="/images/monicle-logo.svg"
                  layout="fixed"
                  width={101}
                  height={19}
                />
              </Link>
            </NextLink>
          </chakra.div>
          <HeadNav
            _inner={{
              py: 3,
              px: { base: 4, lg: 6 },
            }}
          />
        </HStack>
      </chakra.header>
    </>
  )
}

const Main = () => {
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      textAlign="center"
      pt={{ base: 6, lg: 10 }}
      pb={{ base: 16, lg: 20 }}
    >
      <VStack spacing={4}>
        <NextImage src="/images/404.png" width={168} height={115} />
        <chakra.h1 textStyle={"title-bold-23pt"}>
          <Wbr>お探しのページは</Wbr>
          <Wbr>見つかりませんでした。</Wbr>
        </chakra.h1>
        <chakra.p textStyle={"body-medium-14pt"}>
          <Wbr>アクセスしようとしたページは削除、</Wbr>
          <Wbr>変更された可能性があります。</Wbr>
          <br />
          お手数ですが、サイトトップにお戻りください。
        </chakra.p>
      </VStack>
      <Center
        as="a"
        href="/"
        position="relative"
        mt={8}
        w="142px"
        border="1px solid #333333"
        borderRadius="30px"
        fontFamily="serif"
        fontWeight={700}
        fontSize="15px"
        letterSpacing={"0.02em"}
        verticalAlign="middle"
        pt="10px"
        pb="7px"
      >
        <RightVHalfArrow
          position="absolute"
          left={2}
          top="10px"
          transform="scaleX(-1)"
        />
        TOP
      </Center>
    </Flex>
  )
}
