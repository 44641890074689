import {
  Box,
  chakra,
  Divider,
  Flex,
  HStack,
  HTMLChakraProps,
  IconButton,
  Link,
  Slide,
  useBreakpointValue,
  useDisclosure,
  VStack,
} from "@chakra-ui/react"
import { CORPORATE_BASE_URL } from "components/constants"
import { MinusRound, More } from "components/icons/icons"
import { NextImage } from "components/system/NextImage"
import { NextLink, NextLinkProps } from "components/system/NextLink"
import { motion, Variants } from "framer-motion"
import React from "react"
import { RemoveScroll } from "react-remove-scroll"

/** ヘッダーに表示するナビゲーション
 * TODO: メニュー自体のDOMは流用したい
 */
export const HeadNav = ({
  _inner,
  ...props
}: { _inner?: HTMLChakraProps<"div"> } & HTMLChakraProps<"div">) => {
  const expandable =
    useBreakpointValue({ base: true, lg: false }, "base") ?? true

  return (
    <Flex w="full" {...props}>
      {!expandable ? (
        <HorizontalNavContent {..._inner} />
      ) : (
        <ExpaneableVerticalMenu _container={_inner} />
      )}
    </Flex>
  )
}

/** 狭い画面用のメニュー */
const ExpaneableVerticalMenu = (props: {
  _container?: HTMLChakraProps<"div">
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <chakra.div position="fixed" top={0} right={0} zIndex="overlay">
      <chakra.div
        zIndex={1}
        position="relative"
        d="flex"
        flexDirection="row"
        w="full"
        {...props._container}
      >
        {isOpen ? (
          <CloseMenuIcon onClick={onClose} ml="auto" mr={0} />
        ) : (
          <OpenMenuIcon onClick={onOpen} ml="auto" mr={0} />
        )}
      </chakra.div>
      <Slide direction="top" in={isOpen}>
        <RemoveScroll enabled={isOpen}>
          <Flex
            {...props._container}
            flexDirection={"column"}
            w="full"
            left={0}
            right={0}
            top={0}
            bottom={0}
            bgColor={"text-main-black"}
            color="white"
            h="100vh"
          >
            <chakra.div overflowY="auto">
              <VStack mt={6}>
                <chakra.div py={8}>
                  <chakra.img
                    src="/images/monicle-logo-white.svg"
                    alt="monicle"
                    width="250px"
                    height="auto"
                  />
                </chakra.div>
                <motion.div
                  initial="hidden"
                  animate={isOpen ? "visible" : "hidden"}
                  variants={menuAnimation}
                >
                  {ExpanedeHeaderLinks.map((l, i) => (
                    <motion.div variants={menuItemAnimation} key={i}>
                      <Flex w="250px" mx="auto" mt={9} alignItems="center">
                        <Box flex={1}>
                          <Divider borderColor="white" />
                        </Box>
                        <Box w="70px" mx={2} whiteSpace="nowrap">
                          <NextLink href={l.href} passHref>
                            <chakra.a
                              onClick={() => {
                                onClose()
                              }}
                              textStyle={"en-title-bold-28pt"}
                            >
                              {l.label}
                            </chakra.a>
                          </NextLink>
                        </Box>
                        <Box flex={1} />
                      </Flex>
                    </motion.div>
                  ))}
                  <motion.div variants={menuItemAnimation}>
                    <VStack my={16} spacing={6}>
                      <chakra.a
                        href="https://media.monicle.co.jp/"
                        target="_blank"
                        rel="noopener"
                        w="full"
                        display="block"
                      >
                        <NextImage
                          src="/images/media/logo.svg"
                          width={365}
                          height={49}
                        />
                      </chakra.a>
                      <chakra.a
                        display="inline-block"
                        href="https://developers.monicle.co.jp/"
                        target="_blank"
                        rel="noopener"
                        w={"full"}
                        py={{ base: 4, lg: 2 }}
                        border="1px solid #FFFFFF"
                      >
                        <VStack spacing={1}>
                          <chakra.img
                            src="/images/monicle-logo-white.svg"
                            w={"120px"}
                          />
                          <chakra.p
                            color="white"
                            fontFamily="Montserrat"
                            fontWeight={600}
                            fontSize={"20px"}
                            lineHeight={"24px"}
                            letterSpacing="0.05em"
                          >
                            developers
                          </chakra.p>
                        </VStack>
                      </chakra.a>
                    </VStack>
                  </motion.div>
                  <motion.div variants={menuItemAnimation}>
                    <Flex wrap="wrap" gap={2}>
                      {SubLinks.map((l) => (
                        <NextLink key={l.key} href={l.href} passHref>
                          <chakra.a
                            onClick={() => {
                              onClose()
                            }}
                            fontWeight={700}
                            fontSize="12px"
                            lineHeight="140%"
                            letterSpacing="0.05em"
                          >
                            {l.label}
                          </chakra.a>
                        </NextLink>
                      ))}
                    </Flex>
                  </motion.div>
                </motion.div>
              </VStack>
            </chakra.div>
          </Flex>
        </RemoveScroll>
      </Slide>
    </chakra.div>
  )
}

const menuAnimation: Variants = {
  hidden: {
    opacity: 0,
    transition: {
      when: "afterChildren",
      delay: 0.15,
    },
  },
  visible: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.1,
      delay: 0.15,
    },
  },
}
const menuItemAnimation: Variants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
}

/** 広い画面用の展開済みメニュー */
const HorizontalNavContent = (props: HTMLChakraProps<"div">) => (
  <HStack
    ml="auto"
    spacing={8}
    as="nav"
    {...props}
    filter="auto"
    dropShadow="0px 0px 10px white" // Theme.shadowsが効かない
  >
    {HeaderLinks.map((l) => (
      <NextLink key={l.key} href={l.href} passHref>
        <Link textStyle="en-title-bold-28pt" color="text-main-black">
          {l.label}
        </Link>
      </NextLink>
    ))}
  </HStack>
)

const OpenMenuIcon = (props: HTMLChakraProps<"button">) => (
  <IconButton
    {...props}
    icon={<More />}
    variant={"solid"}
    isRound
    size="xs"
    boxSize="32px"
    fontSize="18px"
    color="white"
    bgColor="text-main-black"
    _hover={{
      bgColor: "text-main-black",
    }}
    _active={{
      bgColor: "text-main-black",
    }}
    aria-label="メニューを開く"
  >
    Open
  </IconButton>
)

const CloseMenuIcon = (props: HTMLChakraProps<"button">) => {
  return (
    <IconButton
      {...props}
      icon={<MinusRound />}
      variant={"unstyled"}
      isRound
      size="xs"
      boxSize="32px"
      fontSize="18px"
      color="text-main-black"
      bgColor="white"
      aria-label="メニューを閉じる"
    />
  )
}

const HeaderLinks: {
  key: string
  href: NextLinkProps["href"]
  label: string
}[] = [
  { key: "news", href: `${CORPORATE_BASE_URL}/news`, label: "news" },
  { key: "group", href: `${CORPORATE_BASE_URL}/group`, label: "group" },
  { key: "company", href: `${CORPORATE_BASE_URL}/company`, label: "company" },
  { key: "recruit", href: "/", label: "recruit" },
]
const ExpanedeHeaderLinks: {
  key: string
  href: NextLinkProps["href"]
  label: string
}[] = [
  { key: "home", href: `${CORPORATE_BASE_URL}/`, label: "home" },
  { key: "news", href: `${CORPORATE_BASE_URL}/news`, label: "news" },
  { key: "group", href: `${CORPORATE_BASE_URL}/group`, label: "group" },
  { key: "company", href: `${CORPORATE_BASE_URL}/company`, label: "company" },
  { key: "recruit", href: "/", label: "recruit" },
]

const SubLinks: {
  key: string
  href: NextLinkProps["href"]
  label: string
}[] = [
  {
    key: "privacy",
    href: `${CORPORATE_BASE_URL}/privacy`,
    label: "個人情報保護方針",
  },
  {
    key: "security",
    href: `${CORPORATE_BASE_URL}/security`,
    label: "情報セキュリティ方針",
  },
  {
    key: "terms-of-use",
    href: `${CORPORATE_BASE_URL}/terms-of-use`,
    label: "ご利用にあたって",
  },
  {
    key: "external_transmission",
    href: `${CORPORATE_BASE_URL}/external_transmission`,
    label: "お客様情報の収集と外部送信について",
  },
  {
    key: "contact",
    href: `${CORPORATE_BASE_URL}/contact`,
    label: "お問い合わせ",
  },
]
